<template>
  <div class="add-file" @click="setAttachmentFilesModalProps({ module })">
    <button v-b-tooltip.hover title="Anexar arquivo" class="btn">
      <Paperclip /> 
    </button>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Paperclip from '@/assets/icons/paperclip2.svg'

export default {
  props: {
    module: { type: String, required: true }
  },
  components: { Paperclip },
  methods: {
    ...mapActions('attendance/filesModal', ['setAttachmentFilesModalProps']),
  }
}
</script>
<style lang="scss" scoped>
  .add-file {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    cursor: pointer;
  }
</style>
